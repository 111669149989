<template>
  <div class="caisse-container">
    <grid></grid>
  </div>
</template>
<script>

export default {
  components: {
    Grid: () => import('@custom/caisse/Grid'),
  },
  data: () => ({

  }),
  computed: {

  },
  mounted() {

  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
.caisse-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
}
</style>
